/*
 * @Author: 宋佩兰
 * @Date: 2020-02-12 17:22:32
 * @LastEditors: 赵岩
 * @LastEditTime: 2020-03-14 12:44:59
 * @Description: 产品列表Cell
 */
import React from 'react'
import './index.scss'
import api from '../../api'
import { Toast,Text } from 'antd-mobile';


const topIconList = [
  'https://ligou.s3.cn-north-1.jdcloud-oss.com/ligou/uploadResources/userUpload/2020-03-13/5b3617dbb64f4880b8bb8248d06dccd5.png',
  'https://ligou.s3.cn-north-1.jdcloud-oss.com/ligou/uploadResources/userUpload/2020-03-13/431a6d17fb45453cbda6995f57a7d47d.png',
  'https://ligou.s3.cn-north-1.jdcloud-oss.com/ligou/uploadResources/userUpload/2020-03-13/353c8b47e3d9410681f18f99f9d68264.png',
]

const product_JD_type={
  owner:"g", // 自营
  pop:"p" // 推广
}
class ProductCell extends React.Component {
  state = {
      showSwiper: false,
      vId:"",
  }

  itemClick(productUrl,coupleUrl,productBuyUrl){

      console.log("userid:"+this.state.vId);
      console.log("productUrl:"+productUrl);
      console.log("coupleUrl:"+coupleUrl);
      if(productUrl==null){
          productUrl="";
      }
      if(coupleUrl==null){
          coupleUrl="";
      }

      this.api_productTrans(productUrl,coupleUrl,productBuyUrl);
   
  }

    //转链
    async api_productTrans(productUrl,coupleUrl,productBuyUrl){
        let res = await api.newProductInfoTran({flag:1, materialUrl:productUrl,couponLink:coupleUrl,productUrl:productBuyUrl,fakeId:this.state.vId+'|WEB'})
        if(res.code == 20000){
            let data = res.data
            
            if(data.shortURL.indexOf('https://u.jd.com/') != -1)
              window.location=data.shortURL;
            else
              Toast.show(data.shortURL);
        }
    }




  renderProductBox(){
    let {taskStatus,id,productName,owner,productDescribe,productImg,conponPrice,price,priceDiscount,productBuyUrl, coupleUrl,productUrl} = this.props.data || {}
    let pImg =productImg && productImg.split(",")[0]
    return (
        <div className="product_box"  onClick={(e)=>{this.itemClick(productUrl, coupleUrl, productBuyUrl)}}>
              <img className="box_image" src={pImg}></img>
              <div className="box_info">
                  <div className="title">
                      {productName}
                  </div>
                  <div className="price">
                      <div className="price_newer">
                         ¥{priceDiscount}
                      </div>
                      <div className="price_older">
                          ¥{price}
                      </div>
                      <div style={{clear:"both"}}></div>
                  </div>
                  <div className="numsBox">
                      {conponPrice ? <div className="coupon" >
                        <div className="couponText">券</div>
                        <div className="couponMoney">¥{conponPrice}</div>
                      </div> :<div></div>}
                  </div>
              </div>
        </div>
    )
  }
  

 
  renderProduct() {


      let {productName,owner,productImg,conponPrice,price,priceDiscount,goodcommentsshare,comments,commission,skuId, productBuyUrl, coupleUrl,productUrl, couponPrice, couponAmount, couponUrl} = this.props.data || {}
      let pImg =productImg && productImg.split(",")[0]
      return (
          <div className="product_container">
              <img className="pro_img" src={pImg}/>
              <div className="productInfo">
                  <div className="titleBox">
                      
                      <div>
                        <Text className="product_type">
                        {owner == product_JD_type.pop?' 京东 ':''}
                        {owner == product_JD_type.owner?' 京东自营 ':''}
                        </Text>
                        &nbsp;{productName}  
                      </div>
                  </div>
                  <div className="numsBox">
                      {conponPrice && parseFloat(conponPrice)>0 && <div className="coupon" >
                          <div className="couponText">券</div>
                          <div className="couponMoney">¥{conponPrice}</div>
                      </div>}

                      {couponAmount && parseFloat(couponAmount)>0 && <div className="coupon" >
                          <div className="couponText">券</div>
                          <div className="couponMoney">¥{couponAmount}</div>
                      </div>}
                      <div></div>
                      <div className="nums">
                          <div className="nBox">
                              <span>评论数{comments}</span>&nbsp;&nbsp;
                          </div>
                          <div className="nBox">
                              <span>好评率{goodcommentsshare}%</span>
                          </div>
                      </div>

                  </div>

                  <div className="priceBox">
                      <div className="newPrice">
                          {priceDiscount?`¥${priceDiscount}`:''}
                          {couponPrice?`¥${couponPrice}`:''}
                      </div>
                      <div className="oldPrice">
                      &nbsp;&nbsp;¥{price}
                      </div>

                      <div className="buy" onClick={(e)=>{this.itemClick(productUrl,coupleUrl?coupleUrl:couponUrl,productBuyUrl)}}>抢购</div>

                  </div>

              </div>
          </div>
      )
  }

  render(){
    let {type,vId} = this.props
    this.state.vId=vId;
    if(type == 'box'){
      return this.renderProductBox()
    }else{
      return this.renderProduct()
    }
  }



}

export default ProductCell
