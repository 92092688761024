let API_EVN = window.location.host == "shop.lig.cn"?"production":"development"
let API_HOST = ""
// window.location.host == "manage.lig.cn"
// ? `https://wxapi.lig.cn${url}`
// : `https://test.lig.cn${url}`,
if (API_EVN === 'development') {
    API_HOST = 'https://wxapi.lig.cn/'
} else if (API_EVN === 'production') {
    API_HOST = 'https://wxapi.lig.cn/'
}

export {
    API_HOST
}