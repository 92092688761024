import React from 'react'
import {Text, View, NavBar} from 'antd-mobile';
import './style.scss'
import UserInfoStore from '../../store/userInfo'
import Icon from "antd-mobile/es/icon";


class RegistrySuccess extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            height: document.documentElement.clientHeight,

        };
    }

    render() {

        let userInfo = UserInfoStore.getUserInfo()

        return (
            <div>
                <NavBar
                    className="nav"
                    mode="light"
                    icon={<Icon type="left" color={"#000000"} />}
                    onLeftClick={() => {window.history.go(-2);}}
                    // rightContent={[
                    //     //<Icon key="0" type="search" style={{ marginRight: '16px' }} />,
                    //     <Icon key="1" type="ellipsis"  color={"#000000"} />,
                    // ]}
                >
                    {userInfo.upper.shopName?userInfo.upper.shopName:(userInfo.upper.username+'的店')}
                </NavBar>

                <View className="content_box">

                    {this.state.exist &&
                    <Text className="tips">恭喜您，已是立购联盟的注册用户！</Text>}
                    {!this.state.exist &&
                    <Text className="tips">恭喜您，注册成功！</Text>}
                    <img
                        style={{ width: '100px', height: '100px',marginTop:'20px'}}
                        src={require('../../images/ligou.jpg')}
                    />
                    <View className="save_code">保存二维码</View>
                    <View className="step">第一步</View>
                    <Text className="step_one_text">保存二维码后打开微信扫一扫</Text>
                    <View className="step">第二步</View>
                    <Text className="step_one_text step_two_text">打开小程序后，务必点击</Text>
                    <Text className="step_one_text step_two_text step_text_margin step_text_color"><b>【已有账号，直接登录】</b></Text>
                    <Text className="step_one_text step_two_text step_text_margin">输入刚刚注册的手机号和验证码即可完成登录。（如下图所示）</Text>
                    <img
                        style={{ width: '324px', height: '252px',marginTop:'20px'}}
                        src={require('../../images/success_tip.jpg')}
                    />

                </View>

            </div>
        )
    }
}


export default RegistrySuccess


