import React from 'react'
import { Text,View,Toast,NavBar,InputItem } from 'antd-mobile';
import Icon from "antd-mobile/es/icon";
import './style.scss'
// import 'swiper/swiper.less'
import {REQUEST_CODE} from "../../constants"
import api from '../../api'
import UserInfoStore from '../../store/userInfo'


class Registry extends React.Component{

    constructor(props) {
        super(props);

        this.state = {    
            height: document.documentElement.clientHeight,
            hasError: false,
            phone: '',
            chkCode:'',
        };
    }

    onErrorClick = () => {
        if (this.state.hasError) {
            Toast.info('请输入11位手机号码');
        }
    }
    onChange = (value) => {
        if (value.replace(/\s/g, '').length < 11) {
            this.setState({
            hasError: true,
            });
        } else {
            this.setState({
            hasError: false,
            });
        }
        this.setState({
            phone:value.replace(/\s+/g,"")
        });
    }

    async api_getQrCode(){
        let userInfo = UserInfoStore.getUserInfo();
        let params = {
            scene:`inviteCode=${userInfo.upper.code}`,
            width:180,
            // page:"pages/index/index",
            page:"pages/index",
            auto_color:true,
            is_hyaline:false,
        }
        let res = await api.getWxQRcode(params).catch((err)=>{
            Toast.hideLoading()
            Toast.showToast({ icon: 'none', title: '接口请求超时,二维码获取失败' });
            console.log('err',err)})
        if(res.code == REQUEST_CODE.SUCCESS){
            let base64 = res.data
            // base64src(base64,'user_'+userInfo.code, res_base64 => {
            //     this.initConfig(res_base64)
            // });
            console.log(base64);
        }else{
            Toast.showToast({title: '二维码获取失败',icon: 'none'})
        }



    }

    async api_getChkCode(){

        let res = await api.getPhoneRegCode({phone:this.state.phone}).catch((err)=>{
            console.log('err',err);
        });
        if(res && res.code == 20000){
            Toast.show("验证码已成功发送")
        }else if(res.code == 50007){
            Toast.info("账号已存在，请直接使用小程序")

        }else{
            Toast.show("未请求到数据")
        }
    }

    async api_regByPhoneCode(){

        let userInfo = UserInfoStore.getUserInfo();
        let param = {
            code:this.state.chkCode,
            phone:this.state.phone,
            recommandCode:userInfo.upper.code
        }
        let res = await api.regByPhoneCode(param);
        if(res && res.code == 20000){
            this.props.history.push({ pathname:'/registrysuccess'})
        }
    }

    render() {
        // let {swiper,tabs,topProduct,goodProduct,useBodyScroll} = this.state
        let userInfo = UserInfoStore.getUserInfo()

        return (
            <div>
                
                <NavBar
                    className="nav"
                    mode="light"
                    icon={<Icon type="left" color={"#000000"} />}
                    onLeftClick={() => {window.history.go(-1);}}
                    // rightContent={[
                    //     //<Icon key="0" type="search" style={{ marginRight: '16px' }} />,
                    //     <Icon key="1" type="ellipsis"  color={"#000000"} />,
                    // ]}
                >
                {userInfo.upper.shopName?userInfo.upper.shopName:(userInfo.upper.username+'的店')}
                
                </NavBar>
                
                <div>
                    <img 
                        src={require('../../images/registry/page_bg.jpg')} 
                        style={{ width: '100%', height: '100%', position: 'absolute', zIndex:'-2'}}
                        />
                    <img 
                        src={require('../../images/registry/dog.png')} 
                        style={{ width: '110px', height: '113px', position: 'absolute', zIndex:'-1',marginTop:'60%'}}
                        />
                    <View className="input">
                        <InputItem
                            type="phone"
                            className="phone"
                            clear
                            placeholder="请输入手机号"
                            error={this.state.hasError}
                            onErrorClick={this.onErrorClick}
                            onChange={this.onChange}
                            value={this.state.phone}
                            
                        ></InputItem>
                        <View className="checkBox">
                            <InputItem 
                                type="text"
                                className="code"
                                clear
                                placeholder="请输入验证码"
                                onChange={(value)=>{
                                    this.setState({
                                        chkCode:value.replace(/\s+/g,"")
                                    });
                                }}
                                value={this.state.chkCode}
                                
                            ></InputItem>
                            <img 
                                src={require('../../images/registry/code_btn.png')} 
                                style={{ width: '103px', height: '44px'}}
                                onClick={() => {
                                    if (this.state.hasError) {
                                        Toast.info('请输入11位手机号码');
                                        return;
                                    }
                                    if (this.state.phone.length==0){
                                        Toast.info('请输入11位手机号码');
                                        return;
                                    }
                                    this.api_getChkCode();
                                }}/>
                        </View>
                        <img 
                            className="join_btn"
                            src={require('../../images/registry/join_btn.png')} 
                            onClick={() => {
                                if (this.state.hasError) {
                                    Toast.info('请输入11位手机号码');
                                    return;
                                }
                                if (this.state.phone.length==0){
                                    Toast.info('请输入11位手机号码');
                                    return;
                                }
                                if (this.state.chkCode.length==0){
                                    Toast.info('请输入11位手机号码');
                                    return;
                                }
                                this.api_regByPhoneCode();
                            }}/>
                    </View>

                </div>
                
            </div>
        )
    }
}


export default Registry


