/*
 * @Author: 宋佩兰
 * @Date: 2020-5-14
 * @LastEditors: 宋佩兰
 * @LastEditTime: 2020-5-14
 * @Description: 
 */
import { API_HOST } from '../config'
import request from '../request'

export default {


    myshopyoulike(data={}, showLoading = true){
      return request({
        url: API_HOST + "product/jdqrqm/myshopyoulike",
        data: data,
        method: "POST",
        showLoading: showLoading,
        showErrToast: true
      });
    },

    /**
     * 获取验证码
     */
    regByPhoneCode(data = {}, showLoading = true){
      return request({
        url: API_HOST + "user/login/regByPhoneCode",
        data: data,
        method: "POST",
        showLoading: showLoading,
        showErrToast: true
      });
    },

    /**
     * 获取验证码
     */
    getPhoneRegCode(data = {}, showLoading = true){
      return request({
        url: API_HOST + "user/login/getPhoneRegCode",
        data: data,
        method: "POST",
        showLoading: showLoading,
        showErrToast: true
      });
    },

    /**
     * 获取首页模板
     */
    getHomData( data = {}, showLoading = true) {
        return request({
            url: API_HOST + "product/myshop/myshop",
            data: data,
            method: "POST",
            showLoading: showLoading,
            showErrToast: true
        });
    },



    /**
     * 获取用户ID
     */
    getUserID( data = {}, showLoading = false) {
        return request({
            url: API_HOST + "product/myshop/getUserInfo",
            data: data,
            method: "POST",
            showLoading: showLoading,
            showErrToast: true
        });
    },


    /**
     * 获取首页热门商品
    */
    hotProductList(data = {}, showLoading = true) {
        return request({
            url: API_HOST + "product/home/gettopProduct",
            data: data,
            method: "GET",
            showLoading: showLoading,
            showErrToast: true
          });
    },

    getTop(data = {}, showLoading = true) {
        return request({
          url: API_HOST + "product/home2/getProductList",
          data: data,
          method: "POST",
          showLoading: showLoading,
          showErrToast: true
        });
    },

    /**
     * 获取商品列表
    */
   getProductList(data = {}, showLoading = true) {
    return request({
        url: API_HOST + "product/home/getProductList",
        data: data,
        method: "POST",
        showLoading: showLoading,
        showErrToast: true
      });
  },
    /*
     * 搜索商品
     * 宋佩兰
     * 
     */
    getProductSearch(data = {}) {
        return request({
            url: API_HOST + "product/myshop/search",
            data: data,
            method: "POST",
            showLoading: true,
            showErrToast: true
          });
    },

     /**  
     * 获取商品详情
    */
   getProductInfo(data = {}) {
    return request({
        url: API_HOST + "product/home/getProductInfo",
        data: data,
        method: "POST",
        showLoading: true,
        showErrToast: true
      });
    },
    /**  
     * new 转链
    */
    newProductInfoTran(data = {}) {
    return request({
        url: API_HOST + "product/tran/newProductInfoTran",
        data: data,
        method: "POST",
        showLoading: true,
        showErrToast: true
      });
    },
    /**  
     * 商品转链
    */
    newTranslation(data = {}) {
      return request({
          url: API_HOST + "product/tran/newTranslation",
          data: data,
          method: "POST",
          showLoading: true,
          showErrToast: true
        });
    },
    /**
     * 获取站内信列表
     */
    getInnerMessageList(data){
        return request({
            url: API_HOST + "product/home/getInnerMessageList",
            data: data,
            method: "POST",
            showLoading: true,
            showErrToast: true
          });
    },
    /**
     * 获取站内信列表
     */
    getInnerMessageInfo(data){
        return request({
            url: API_HOST + "product/home/getInnerMessageInfo?id="+data.id,
            data: data,
            method: "POST",
            showLoading: true,
            showErrToast: true
          });
    },
     /**
     * 获取活动
     */
    getActionInfo(data){
        return request({
            url: API_HOST + "product/myshop/getActionInfo",
            data: data,
            method: "POST",
            showLoading: true,
            showErrToast: true
          });
    },

     /**
     * 获取首页弹窗
     */
    getPopupData(data){
      return request({
          url: API_HOST + "product/home/getPopFrame",
          data: data,
          method: "GET",
          showLoading: false,
          showErrToast: true
        });
  },
  /**
     * 获得站内信时间戳
     */
    getLatestMessageTime(data){
      return request({
          url: API_HOST + "product/home/getInnerMessageSendTime",
          data: data,
          method: "GET",
          showLoading: false,
          showErrToast: true
        });
  },
    /**
     * 限时奖励
     */
    getRewardProductList(data, showLoading = true){
      return request({
          url: API_HOST + "product/home/getRewardProductList",
          data: data,
          method: "GET",
          showLoading: showLoading,
          showErrToast: true
        });
    },
     /**
     * 限时奖励
     */
    linkQRcode(data){
      return request({
          url: API_HOST + "product/home/linkQRcode",
          data: data,
          method: "GET",
          showLoading: false,
          showErrToast: true
        });
    },
    /**
     * 获取素材列表
     */
    getMaterialList(data){
      return request({
          url: API_HOST + "product/material/getMaterialList",
          data: data,
          method: "POST",
          showLoading: false,
          showErrToast: true
        });
    },
    /**
     * 素材分享计数
     */
    addMaterialShareCount(data){
      return request({
          url: API_HOST + "product/material/addMaterialShareCount",
          data: data,
          method: "POST",
          showLoading: false,
          showErrToast: true
        });
    },
    // 生成小程序码（参数32位）
    getWxQRcode(data){
      return request({
          url: API_HOST + "product/home/getWxQRcode",
          data: data,
          method: "POST",
          showLoading: false,
          showErrToast: true
        });
    },
    //生成小程序码（参数位数不限制，需要解析）
    getLocalQRcode(data){
      return request({
          url: API_HOST + "product/home/getLocalQRcode",
          data: data,
          method: "POST",
          showLoading: false,
          showErrToast: true
        });
    },
    // 解析压缩后信息
    getOriginCodeInfo(data){
      return request({
          url: API_HOST + "product/home/getOriginCodeInfo",
          data: data,
          method: "GET",
          showLoading: true,
          showErrToast: true
        });
    },
};
