import React from 'react'
import { NavBar,View } from 'antd-mobile';
import Icon from "antd-mobile/es/icon";
import './style.scss'
import api from '../../api'
import {REQUEST_CODE} from "../../constants"
import UserInfoStore from '../../store/userInfo'

class Activity extends React.Component{
    
    constructor(props) {

        super(props);
        this.state = {
           data:{}
        };
    }

    id=0

    componentDidMount(){
        
        let id = this.props.match.params.id
        console.log(id);
        this.id = id;
        this.api_getData()

    }


    async api_getData(){
        let id = this.id;
        let userInfo = UserInfoStore.getUserInfo()
        let params = {
            actionId:id,
            userId:userInfo.userId
          }
        let res = await api.getActionInfo(params)
        console.log(res)
        if (res && res.code == REQUEST_CODE.SUCCESS) {
            let data = res.data
            this.setState({data:data});
        }else {
            
        }
    }

    componentDidUpdate() {

        let {otherActionDescribe}  = this.state.data;
        let s = /(https:\/\/u.jd.com\/\w+)/g;
        if (s.test(otherActionDescribe)) otherActionDescribe = otherActionDescribe.replace(s,'<a href="$1">$1</a>').trim();
        document.getElementById("action_detail_content").innerHTML = otherActionDescribe;
    }


    render() {
        let {name,beginTime,endTime,uiImage, otherActionImage, otherActionDescribe, otherActionIntroduction }  = this.state.data;
        if(beginTime && beginTime.indexOf('T')!=-1) {
            let a = new Date(beginTime);
            beginTime = a.getFullYear()+'-' +(a.getMonth()+1<10?"0"+(a.getMonth()+1):a.getMonth()+1)+'-'+(a.getDate()<10?"0"+a.getDate():a.getDate())+' '+(a.getHours()<10?"0"+a.getHours():a.getHours()) + ':'+(a.getMinutes()<10?"0"+a.getMinutes():a.getMinutes()) + ':' + (a.getSeconds()<10?"0"+a.getSeconds():a.getSeconds());
        }
        if(endTime && endTime.indexOf('T')!=-1) {
            let a = new Date(endTime);
            endTime = a.getFullYear()+'-' +(a.getMonth()+1<10?"0"+(a.getMonth()+1):a.getMonth()+1)+'-'+(a.getDate()<10?"0"+a.getDate():a.getDate())+' '+(a.getHours()<10?"0"+a.getHours():a.getHours()) + ':'+(a.getMinutes()<10?"0"+a.getMinutes():a.getMinutes()) + ':' + (a.getSeconds()<10?"0"+a.getSeconds():a.getSeconds());
        }
        return (
            <View>
                <NavBar
                className="nav"
                mode="light"
                icon={<Icon type="left" color={"#000000"}  />}
                onLeftClick={() => {window.history.go(-1);}}
                rightContent={[
                    //<Icon key="0" type="search" style={{ marginRight: '16px' }} />,
                //   <Icon key="1" type="ellipsis" color={"#000000"}  />,
                ]}
                >{name?name:''}</NavBar>
                <div className="content">
                  <div className="content_tip1">
                        <div className="content_tip_title">活动介绍</div>
                        <div className="content_tip_content">{ otherActionIntroduction}</div>
                        <div className="content_tip_content">{(beginTime?beginTime:'') + '至' + (endTime?endTime:'')}</div>
                        <br/>
                  </div>
                  <div className="content_tip2">
                      <div className="content_tip_title">活动文案</div>
                      <div id="action_detail_content" className="content_tip_content">
                            
                            {otherActionDescribe}
                            
                          
                      </div>
                      <br/>
                      {/* <br/> */}
                      {/* <a href="https://u.jd.com/jkzaor">https://u.jd.com/jkzaor</a> */}
                  </div>

                  <div className="content_tip3">
                    {/* {uiImage && <img src={uiImage}  className="content_tip_img"/>} */}
                    {otherActionImage && <img src={otherActionImage} className="content_tip_img" />}
                  </div>
                </div>

            </View>
        )
    }
}


export default Activity


