/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
import { extend } from "umi-request";
// import { notification, message } from "antd";
import { Toast } from 'antd-mobile';
import { stringify } from "querystring";
import userInfoStore from '../store/userInfo'
import MD5 from 'crypto-js/md5'

const rqWhite=['/operate/sysUser/login']
let option_g = {}


const DJAK = "trd0kcfkyf1a1vbccneezdnggg3trf39hqj81yey2xb7g40jbxjkjeapfucj5rhf";
const DJSK = "w34rhczt7avaubmcerfikv78nw42pk6m0suciyazyvu4dxa4edx6hq22an3a2y1r";




const codeMessage = {
  20000: "服务器成功返回请求的数据。",
  201: "新建或修改数据成功。",
  202: "一个请求已经进入后台排队（异步任务）。",
  204: "删除数据成功。",
  400: "发出的请求有错误，服务器没有进行新建或修改数据的操作。",
  403: "用户得到授权，但是访问是被禁止的。",
  404: "发出的请求针对的是不存在的记录，服务器没有进行操作。",
  406: "请求的格式不可得。",
  410: "请求的资源被永久删除，且不会再得到的。",
  422: "当创建一个对象时，发生一个验证错误。",
  500: "服务器发生错误，请检查服务器。",
  502: "网关错误。",
  503: "服务不可用，服务器暂时过载或维护。",
  504: "网关超时。"
};
/**
 * 异常处理程序
 */

const errorHandler = error => {
  const { response } = error;

  if (response && response.status) {
    const errorText = codeMessage[response.status] || response.statusText;
    const { status, url } = response;
    Toast.fail(errorText, 1);
    // notification.error({
    //   message: `请求错误 ${status}: ${url}`,
    //   description: errorText
    // });
  }else if (!response) {
    Toast.fail("您的网络发生异常，无法连接服务器", 1);
    // notification.error({
    //   description: "您的网络发生异常，无法连接服务器",
    //   message: "网络异常"
    // });
  }

  return response;
};
/**
 * 配置request请求时的默认参数
 */

const request = extend({
  errorHandler,
  timeout: 15000
  // 默认错误处理
  // credentials: "include" // 默认请求是否带上cookie
});

request.use(async (ctx, next) => {

  await next();
  option_g.showLoading && Toast.hide()
  const { res } = ctx;
  if(res && res.code != 20000){
    option_g.showErrToast && Toast.fail(res.msg, 1);
  }
})

// request拦截器, 改变url 或 options.
request.interceptors.request.use(async (url, options) => {
  
  return {
    url:url,
    options: {
      ...options,
    }
  };
});


const rq = options => {


  let token = userInfoStore.getUserInfo().token; //userInfo.userInfoData.token;
  let { url, data, header, method = 'GET', showLoading = true, showErrToast = true } = options
  option_g = options
  let headerToken = token ? { authorization: token } : {}
  header = {...header, ...headerToken}
  
  let timestamp = (Date.now() + "");
  let allParameter = {...data,ak:DJAK,timestamp}
  let allParameterkeys = Object.keys(allParameter)
  let sortArr = allParameterkeys.sort();
  let sign = "";
  for (let index = 0; index < sortArr.length; index++) {
    const key = sortArr[index];
    let value = allParameter[key];
    if (Array.isArray(value) || Object.prototype.toString.call(value) == "[object Object]") {
      value = JSON.stringify(value);
    } 
    sign = sign + key + value;
  }
  sign = MD5(sign + DJSK).toString();

  let parameter = {...data,timestamp,ak:DJAK,sign}
  method = method.toUpperCase()
  showLoading && Toast.loading('加载中...', 60 * 1000 * 1000);
  const params = method === "GET"  ? { params: parameter } : { data:parameter };

  header["Content-Type"] = "application/json";
  header["platform"] = "WEB";
  return request(url, {
    method,
    headers:header,
    ...params
  });
};
export default rq;
