import React from 'react'
import './style.scss'
import { NavBar,SearchBar,View,Text,Toast,ListView } from 'antd-mobile';
import Icon from "antd-mobile/es/icon";
import api from "../../api";
import {REQUEST_CODE} from "../../constants"
import ProductCell from "../../components/productCell";
import UserInfoStore from '../../store/userInfo'

class Search extends React.Component{
    constructor(props){
        super(props);
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        })
        this.state = {
            
            useBodyScroll: false,
            searchInputValue:"", // 搜索文本
            navIndex:0, // 导航索引
            navSortStatus:true, // 导航排序状态 箭头方向：正序向下，反序向上
            isJD:false, // 是否京东自营
            isCoupon:false, // 是否是优惠券商品
            productList:[],
            isLoading: false,
            finish:false,
            noData:false,
            dataSource,
        }
    }
    pageIndex=1
    pageSize=20
    navList={
        "0":{
          text:"综合排序",
          key:"",
        },
        "1":{
          text:"佣金比",
          key:"commissionShare",
        },
        "2":{
          text:"佣金",
          key:"commission",
        },
        "3":{
          text:"销量",
          key:"inOrderCount30Days",
        },
        "4":{
          text:"价格",
          key:"price",
        }
      }

    componentDidMount() {
        let w = this.props.location.state.word;
        if(w){
            this.setState({searchInputValue:w},()=>{
              this.searchHandler()
            })
        }
    }

    /** 搜索点击事件 */
    searchHandler(){
        let {searchInputValue} = this.state
        if(searchInputValue && searchInputValue.length > 0){
            this.setState({productList:[],isLoading:true,noData:false,finish:false},()=>{
                this.api_search(1);
            })
        }else{
            Toast.show("请输入搜索内容")
        }
    }

    /* 切换导航，改变排序条件*/
    swichNavHandler(index){
        let {navIndex,navSortStatus} = this.state
        if(navIndex == index){ // 正序 倒序 排列
            this.setState({navSortStatus:!navSortStatus,productList:[],isLoading:true,noData:false,finish:false},()=>{
            this.api_search(1)
            })
        }else{
            this.setState({navIndex:index,productList:[],isLoading:true,noData:false,finish:false},()=>{
            this.api_search(1)
            })
        }
    }

    /* 切换标签 是否京东自营 是否为优惠券商品 */
    tabsHandler(type){
        let {isJD,isCoupon} = this.state
        if(type == 'isJD'){
            this.setState({isJD:!isJD,productList:[],isLoading:true,noData:false,finish:false},()=>{
                this.api_search(1);
            })
        }else{
            this.setState({isCoupon:!isCoupon,productList:[],isLoading:true,noData:false,finish:false},()=>{
                this.api_search(1);
            })
        }
    }

    async api_search(pageNum){
        let {productList,isJD,isCoupon,navSortStatus,searchInputValue,navIndex} = this.state
        let params = {
            keyword:searchInputValue, // 关键词，字数同京东商品名称一致，目前未限制
            sortName:this.navList[navIndex].key, // 排序字段(price：单价, commissionShare：佣金比例, commission：佣金， inOrderCount30Days：30天引单量， inOrderComm30Days：30天支出佣金)
            sort:navSortStatus?"desc":"asc", // asc,desc升降序,默认降序
            isCoupon:isCoupon?1:0, // 是否是优惠券商品，1：有优惠券，0：无优惠券
            owner:isJD?"g":"p", // 商品类型：自营[g]，POP[p]
            pageIndex:pageNum,
            pageSize:this.pageSize
        }
        let res = await api.getProductSearch(params).catch((err)=>{
            console.log("[err]")
            this.setState({
                isLoading: false,
            });
            Toast.show("未请求到数据")
        })

        if(res && res.code == REQUEST_CODE.SUCCESS){
            let {records,total} = res.data
            if(pageNum>1 && records.length == 0){
                this.setState({finish:true})
            }else if(pageNum==1 && records.length == 0){
                this.setState({noData:true})
                
            }else{
                productList.push(...records)
                
                this.setState({
                    productList,
                    dataSource: this.state.dataSource.cloneWithRows(productList),
                    isLoading:false,
                    finish:(productList.length >= total?true:false)
                })
            }
            this.pageIndex = pageNum;
        }else{
            this.setState({
                isLoading: false,
            });
        }
        
    }

    onEndReached = (event) => {

        if (this.state.isLoading || this.state.finish) {
            return;
        }
        // console.log('reach end', event);
        this.setState({isLoading:true},()=>{
            
            this.api_search(this.pageIndex+1);
        })
    }

    render() {
        let {navIndex,navSortStatus,isJD,isCoupon,useBodyScroll,searchInputValue} = this.state;
        let userInfo = UserInfoStore.getUserInfo()

        const row = (rowData) => {
            if(rowData)
            return (
                <ProductCell vId={userInfo.upper.userId} data={rowData} key={'list_'+rowData.id}></ProductCell>
            );
        };
        return (
            <View>
                
                <NavBar
                    className="nav"
                    mode="light"
                    icon={<Icon type="left" color={"#000000"} />}
                    onLeftClick={() => {window.history.go(-1);}}
                    // rightContent={[
                    //     //<Icon key="0" type="search" style={{ marginRight: '16px' }} />,
                    //     <Icon key="1" type="ellipsis"  color={"#000000"} />,
                    // ]}
                >
                {userInfo.upper.shopName?userInfo.upper.shopName:(userInfo.upper.username+'的店')}
                </NavBar>


                <SearchBar placeholder={"搜索商品，分享创造美好生活"} 
                    value={searchInputValue}
                    onCancel={() =>{ this.setState({searchInputValue:''})}}
                    onChange={(value) => {this.setState({searchInputValue:value})}}
                    onSubmit={(value) => {
                        this.setState({searchInputValue:value,
                            navIndex:0, 
                            navSortStatus:true, 
                            isJD:false, 
                            isCoupon:false, 
                            productList:[],
                            isLoading: true,
                            finish:false,
                            noData:false
                        },()=>{
                            this.searchHandler();
                        });
                    }}>
                </SearchBar>


                <View class="menuBox">

                    <View class={`cell ${navIndex == 0?"active":""}`} onClick={this.swichNavHandler.bind(this,0)}>
                    综合排序
                    <img class={`icon ${navIndex == 0 && !navSortStatus ?"upIcon":""}`} src={require("../../images/product/down@3x.png")}/>
                    </View>
                    <View class={`cell ${navIndex == 1?"active":""}`} onClick={this.swichNavHandler.bind(this,1)} >
                    佣金比
                    <img class={`icon ${navIndex == 1 && !navSortStatus ?"upIcon":""}`} src={require("../../images/product/down@3x.png")}/>
                    </View>
                    <View class={`cell ${navIndex == 2?"active":""}`} onClick={this.swichNavHandler.bind(this,2)} >
                    佣金
                    <img class={`icon ${navIndex == 2 && !navSortStatus ?"upIcon":""}`} src={require("../../images/product/down@3x.png")}/>
                    </View>
                    <View class={`cell ${navIndex == 3?"active":""}`} onClick={this.swichNavHandler.bind(this,3)} >
                    销量
                    <img class={`icon ${navIndex == 3 && !navSortStatus ?"upIcon":""}`} src={require("../../images/product/down@3x.png")}/>
                    </View>
                    <View class={`cell ${navIndex == 4?"active":""}`} onClick={this.swichNavHandler.bind(this,4)} >
                    价格
                    <img class={`icon ${navIndex == 4 && !navSortStatus ?"upIcon":""}`} src={require("../../images/product/down@3x.png")}/>
                    </View>
                </View>
                <View class="tabsBox">
                    <View class={`btn ${isJD?'tabBtn_active':''}`} onClick={this.tabsHandler.bind(this,'isJD')} >京东自营</View>
                    <View class={`btn ${isCoupon?'tabBtn_active':''}`} onClick={this.tabsHandler.bind(this,'isCoupon')}>优惠券商品</View>
                </View>

                <ListView
                    
                    key={useBodyScroll ? '0' : '1'}
                    ref={el => (this.lv = el)}
                    dataSource={this.state.dataSource}
                    renderRow={row}
                    // useBodyScroll={useBodyScroll}

                    style= {{
                            height: document.documentElement.clientHeight,
                            }}
                    
                    // style={useBodyScroll? {} : {
                    //         height: document.documentElement.clientHeight,
                    //         }}
                    
                    onEndReached={this.onEndReached}
                    
                    renderFooter={() => (<p >
                                        { this.state.noData && '暂无数据'}
                                        { !this.state.noData && (this.state.finish ? '已经全部加载完毕' : '正在加载更多的数据...')}
                                        </p>)
                                }
                    />

            </View>
        )
    }
}


export default Search


