import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd-mobile/dist/antd-mobile.css'; 

import App from './App';
import Home from './page/home'
import Activity from './page/activity'
import Search from './page/search'
import Registry from './page/registry'

import * as serviceWorker from './serviceWorker';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import RegistrySuccess from "./page/registrysuccess";


// import UserInfoStore from './store/userInfo'

// UserInfoStore.setUserInfo({
//   token:"cf71d1c7760f45ef9249f623823abda6"
// })

const routes = [

    {
      path: "/home/:code",
      component: Home
    },
    {
      path: "/activity/:id",
      component: Activity
    },
    {
      path: "/search",
      component: Search
    },
    {
      path: "/registry",
      component: Registry
    },
    {
        path: "/registrysuccess",
        component: RegistrySuccess
    },
  // {
  //   path: "/tacos",
  //   component: Tacos,
  //   routes: [
  //     {
  //       path: "/tacos/bus",
  //       component: Bus
  //     },
  //     {
  //       path: "/tacos/cart",
  //       component: Cart
  //     }
  //   ]
  // }
];

export default function RouteConfigExample() {
  return (
    <Router>
      <div>
        {/* <ul>
        <li>
            <Link to="/home">home</Link>
          </li>
          <li>
            <Link to="/activity">activity</Link>
          </li>
          <li>
            <Link to="/tacos">Tacos</Link>
          </li>
          <li>
            <Link to="/sandwiches">Sandwiches</Link>
          </li>
        </ul> */}
        <Switch>
          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
        </Switch>
      </div>
    </Router>
  );
}

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={props => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
}

ReactDOM.render(
  <React.StrictMode>
    <RouteConfigExample />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
