
let key ='Alliance_h5_user'
export default {
    userInfo:{},
    setUserInfo(data){
        localStorage.setItem(key, JSON.stringify(data));
    },
    getUserInfo(){
        return JSON.parse(localStorage.getItem(key)||"{}")
    }
}