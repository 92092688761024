import React from 'react'
// import Swiper from 'swiper'
// import { ScrollView} from 'react-native';
import { SearchBar, Tabs, View, ListView, Toast, NavBar, PullToRefresh } from 'antd-mobile';
// import Icon from "antd-mobile/es/icon";
import ProductCell from '../../components/productCell'
import './style.scss'
// import 'swiper/swiper.less'
import api from '../../api'
import UserInfoStore from '../../store/userInfo'

class Home extends React.Component{

    constructor(props) {
        super(props);

        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        })

        this.state = {
            vId:"",
            uId:null,
            currtab:{title: 'TOP榜', cd1: 0},
            secondtabSelect:{title: '热门TOP榜', cd1: 0 },
            swiper:[],
            tabs:[
                { title: 'TOP榜', cd1: 0 },
                { title: '生鲜', cd1: 12218 },
                { title: '母婴', cd1: 1319 },
                { title: '食品', cd1: 1320 },
                { title: '服饰', cd1: 1315 },
                { title: '美妆', cd1: 1316 },
                { title: '运动户外', cd1: 1318 },
                { title: '箱包', cd1: 17329 },
                { title: '玩具', cd1: 6233 },
                { title: '家具', cd1: 9847 },
                { title: '家纺', cd1: 15248 },
                { title: '家居日用', cd1: 1620 },
                { title: '家庭清洁', cd1: 15901 },
                { title: '厨具', cd1: 6196 },
                { title: '数码', cd1: 652 },
                { title: '家用电器', cd1: 737 },
                { title: '宠物', cd1: 6994 },
                { title: '图书', cd1: 1713 },
                { title: '护理', cd1: 16750 },
                { title: '医药保健', cd1: 9192 },
                { title: '汽车生活', cd1: 6728 },
                { title: '礼品鲜花', cd1: 1672 },
                { title: '钟表', cd1: 5025 },
                { title: '酒类', cd1: 12259 },
                { title: '鞋靴', cd1: 11729 },
                { title: '珠宝', cd1: 6144 },
                { title: '家装', cd1: 9855 },
            ],

            second_tabs_1:[
                { title: '猜你喜欢', cd1: 1 },
                { title: '热门TOP榜', cd1: 0 },
                { title: '9.9包邮', cd1: 20097 },
                { title: '家居日用', cd1: 1620 },
            ],
            
            second_tabs_2:[
                { title: '热门TOP榜', cd1: 0 },
                { title: '9.9包邮', cd1: 20097 },
                { title: '家居日用', cd1: 1620 },
            ],

            useBodyScroll: true,
            topProduct:[],
            goodProduct:[],
            dataSource,
            refreshing: false,
            isLoading: false,
            finish:false,
            noData:false,
        };
    }

    pageSize = 20;
    pageIndex = 1;


    async componentDidMount(){
        let code = this.props.match.params.code

        if(this.props.location.search){
            let uid = this.props.location.search.match(new RegExp("[\?\&]id=([^\&]*)(\&?)", "i"));
            if(uid){
                this.setState({uId:uid[1]});
            }
            // console.log(uid ? uid[1] : null);
        }
        

        this.api_getUserId(code)

        // this.api_getHomeData(code)
        this.api_getTop(1);
    }

    componentDidUpdate() {
        const { useBodyScroll } = this.state
        if (useBodyScroll) {
          document.body.style.overflow = 'auto'
        } else {
          document.body.style.overflow = 'hidden'
        }
      }

    async api_getUserId(code){
        let res = await api.getUserID({recomdCode:code})
        if(res && res.code == 20000){
            let data = res.data
            data = Object.assign(data, { code: code })
            this.setState({vId:data.userId})
            UserInfoStore.setUserInfo({upper:data})
            document.title = (data.shopName?data.shopName:(data.username+'的店'))
            if(this.state.uId)
            this.api_getUserLike(1)
            // else
            // this.api_gettopProduct()
        }
    }

    async api_getHomeData(code){
        let res = await api.getHomData({recomdCode:code})
        if(res && res.code == 20000){
            let data = res.data
            let swiperData = data.lunbotu
            let goodProductData = data.yanxuan|| []
            this.setState({
                swiper:swiperData,
                goodProduct:goodProductData,
                isLoading: false,});

        }
    }

    async api_getTop(pageNum) {
        let {topProduct} = this.state;
        Toast.loading('Loading...', 0, null, true)
        let res = await api.getTop({id: "253", pageNum:pageNum,pageSize:this.pageSize})
        if(res && res.code == 20000){
            Toast.hide()
            let {data} = res
            let records = data;
            // console.log(records)
            if(pageNum == 1 && records.length == 0){
                this.setState({noData:true})
            }else if(pageNum >=1 && records.length < this.pageSize){
                this.setState({finish:true})
            }
            topProduct.push(...records)
            this.setState({
                topProduct:topProduct,
                dataSource: this.state.dataSource.cloneWithRows(topProduct),
                isLoading:false,
                refreshing: false,
            })
            this.pageIndex = pageNum;
        }else{
            Toast.hide()
            this.setState({
                refreshing: false,
                isLoading: false,
            });
            Toast.show("未请求到数据")
        }
    }

    // 热门top榜
    async api_gettopProduct(){
        let res = await api.hotProductList()
        if(res && res.code == 20000){
            let data = res.data
            // this.setState({topProduct:data});
            this.setState({
                topProduct:data,
                // dataSource: this.state.dataSource.cloneWithRows(data),
                isLoading:false,
                refreshing: false,
                finish:true,
            })
        }
    }

    //获取分类列表
    async api_getProductList(id,pageNum){
        let {topProduct} = this.state;
        Toast.loading('Loading...', 0, null, true)
        let res = await api.getProductList({id:id,pageNum:pageNum,pageSize:this.pageSize})
        if(res && res.code == 20000){
            Toast.hide()
            let {records} = res.data
            console.log(records)

            if(pageNum == 1 && records.length == 0){
                this.setState({noData:true})
            }else if(pageNum >=1 && records.length < this.pageSize){
                this.setState({finish:true})
            }
            topProduct.push(...records)
            if(this.state.currtab.cd1!=0) {
                this.setState({
                    topProduct:topProduct,
                    dataSource: this.state.dataSource.cloneWithRows(topProduct),
                    isLoading:false,
                    refreshing: false,
                })
            }else{
                this.setState({
                    topProduct:topProduct,
                    isLoading:false,
                    refreshing: false,
                })
            }
            this.pageIndex = pageNum;

        }else{
            Toast.hide()
            this.setState({
                refreshing: false,
                isLoading: false,
            });
            Toast.show("未请求到数据")
        }
    }

    async api_getUserLike(pageNum){
        let {topProduct,uId} = this.state;
        let res = await api.myshopyoulike({userid:uId,pageNum:pageNum,pageSize:this.pageSize});
        // console.log(res);
        if(res && res.code == 20000){
            let {records} = res.data
            if(pageNum == 1 && records.length == 0){
                this.setState({noData:true})
            }else if(pageNum >=1 && records.length < this.pageSize){
                this.setState({finish:true})
            }
            topProduct.push(...records)
            this.setState({
                topProduct:topProduct,
                // dataSource: this.state.dataSource.cloneWithRows(topProduct),
                isLoading:false,
                refreshing: false,
            })
            this.pageIndex = pageNum;            
        }else{
            this.setState({
                refreshing: false,
                isLoading: false,
            });
        }
    }

    onRefresh = () => {
        this.setState({ refreshing: true, isLoading: true })
        setTimeout(() => {
          this.refreshList()
        }, 600)
      }

    refreshList = () => {
        if(this.state.currtab.cd1!=0) {
            this.setState({isLoading:true,finish:false,noData:false,topProduct:[]},()=>{
                this.api_getProductList(this.state.currtab.cd1,1);
            })
        }else{
            let self = this;
            this.setState({isLoading:true,finish:false,noData:false,topProduct:[]},()=>{
            //     if(self.state.secondtabSelect.cd1==0){
            //         self.api_gettopProduct()
            //     }else if(self.state.secondtabSelect.cd1==1){
            //         self.api_getUserLike(1);
            //     }else{
            //         self.api_getProductList(self.state.secondtabSelect.cd1,1);
            //     } 
                self.api_getTop(1);
            })
            
        }
        
    }

    onEndReached = (event) => {
        
        // if(this.state.currtab.cd1==0&& this.state.secondtabSelect.cd1==0){
        //     return;
        // }
        if (this.state.isLoading || this.state.finish) {
            return;
        }
        // console.log('reach end', event);
        if(this.state.currtab.cd1==0){
            this.setState({isLoading:true},()=>{ 
                this.api_getTop(this.pageIndex+1);
            })
        } else {
            this.setState({isLoading:true},()=>{
            
                this.api_getProductList(this.state.currtab.cd1,this.pageIndex+1);
            })
        }
        
    }

    redClick() {
        this.api_translation(this.state.vId+'|WEB');
    }

    //转链
    async api_translation(fakeId){
        let res = await api.newTranslation({content:'https://u.jd.com/lseABVK', fakeId: fakeId})
        if(res.code == 20000){
            let data = res.data
            
            if(data.content && data.content.indexOf('https://u.jd.com/') != -1)
              window.location=data.content;
            else
              Toast.show(data.content);
        }
    }

    render() {
        let {swiper,tabs,second_tabs_1,second_tabs_2,topProduct,goodProduct,useBodyScroll,uId, loading} = this.state
        let userInfo = UserInfoStore.getUserInfo();
        let second_tabs = second_tabs_2;
        if(uId) second_tabs=second_tabs_1
        const row = (rowData) => {
            
            if(rowData)
            return (
                <ProductCell vId={this.state.vId}  data={rowData} key={'list_'+rowData.id}></ProductCell>
            );
        };

        return (
            <View>
                
                {userInfo.upper && 
                <NavBar
                    className="nav"
                    mode="light"
                    // icon={<Icon type="left" color={"#000000"} />}
                    // onLeftClick={() => console.log('onLeftClick')}
                    // rightContent={[
                    //     //<Icon key="0" type="search" style={{ marginRight: '16px' }} />,
                    //     <Icon key="1" type="ellipsis"  color={"#000000"} />,
                    // ]}
                >
                    {userInfo.upper.shopName?userInfo.upper.shopName:(userInfo.upper.username+'的店')}
                </NavBar>}

                <SearchBar className="search" 
                    placeholder={"搜索商品，分享创造美好生活"} 
                    onSubmit={(value) => { 
                        // console.log(`onSubmit${value}`); 
                        this.props.history.push({ pathname:'/search',state:{word : value } })
                    }}
                ></SearchBar>

                <Tabs tabs={tabs}
                      initialPage={0}
                      onChange={(tab, index) => {
                          console.log('onChange', index, tab);
                          this.state.currtab=tabs[index];

                          if(tabs[index].cd1==0){
                            //   this.api_getHomeData()
                              let self=this
                            //   this.setState({secondtabSelect:second_tabs[0],isLoading:true,finish:false,noData:false,topProduct:[]},()=>{
                                
                            //     if(second_tabs[0].cd1==0)
                            //     self.api_gettopProduct()
                            //     else if(second_tabs[0].cd1==1)
                            //     self.api_getUserLike(1);
                            //     else
                            //     self.api_getProductList(tab.cd1, 1);


                            //   })
                            this.setState({isLoading:true,finish:false,noData:false,topProduct:[]},()=>{
                                
                                self.api_getTop(1);
                            })
                          }else{
                              this.setState({isLoading:true,finish:false,noData:false,topProduct:[]},()=>{
                                
                                this.api_getProductList(tabs[index].cd1, 1);
                              })
                          }

                      }}
                      onTabClick={(tab, index) => { console.log('onTabClick', index, tab); }}
                />
                
                {/* {this.state.currtab.cd1==0 &&
                <View>
                    <Carousel
                        autoplay={true}
                        infinite={true}
                        // beforeChange={(from, to) => console.log(`slide from ${from} to ${to}`)}
                        // afterChange={index => console.log('slide to', index)}
                    >
                        { swiper && swiper.map(val => (

                                <img
                                key={val.id}
                                src={val.image}
                                alt=""
                                style={{ width: '100%', verticalAlign: 'top'}}
                                onClick={() => {
                                    if(val.infoType==14 || val.infoType==13){
                                        this.props.history.push('/activity/'+val.infoId)
                                    }
                                    if(val.infoType==0){
                                        
                                    }
                                }}
                                onLoad={() => {
                                    // fire window resize event to change height
                                    window.dispatchEvent(new Event('resize'));
                                    this.setState({ imgHeight: 'auto' });
                                }}
                                />
                        ))}
                    </Carousel>
                    <View className="first_div_container">
                        <View className="first_div" >
                            <View>
                            <img src={require('../../images/subNav_left.png')} />
                            <Text className="first_div_title">京东好货严选</Text>
                            <img src={require('../../images/subNav_right.png')} />
                            </View>
                        </View>
                        <View className="first_div first_div_desc">严控品质为您把关</View>
                    </View>
                    <View className="content-box">
                        <View className="content-box-scroll">
                        {goodProduct && goodProduct.map((item,index)=>{
                            return <ProductCell vId={this.state.vId} type="box" data={item} key={'top_'+index}></ProductCell>
                        })}
                        </View>
                    </View>
                    {userInfo.upper && -1 != 'YDXC88,1IZ3LX,'.indexOf(userInfo.upper.code+',') && <View className="second_div">
                                <img
                                src={require('../../images/click_me.png')}
                                onClick={() => {
                                    this.props.history.push({ pathname:'/registry'})
                                }}
                                onLoad={() => {
                                    // fire window resize event to change height
                                    window.dispatchEvent(new Event('resize'));
                                    this.setState({ imgHeight: 'auto' });
                                }}>
                                </img>
                    </View>}
                    <Tabs tabs={second_tabs}
                        initialPage={0}
                        onChange={(tab, index) => {
                            console.log('onChange', index, tab);
                            let self=this
                            this.setState({secondtabSelect:tab,isLoading:true,finish:false,noData:false,topProduct:[]},()=>{
                                if(tab.cd1==0)
                                self.api_gettopProduct()
                                else if(tab.cd1==1)
                                self.api_getUserLike(1);
                                else
                                self.api_getProductList(tab.cd1, 1);
                            })
                            

                        }}
                        onTabClick={(tab, index) => { console.log('onTabClick', index, tab); }}
                    />

                    <View style={{minHeight:'400px'}}>
                    {topProduct && topProduct.map((item,index)=>{
                        return <ProductCell vId={this.state.vId} data={item} key={'top1_'+index}></ProductCell>
                    })}
                    </View>


                </View>}  */}
                    

                {/* {this.state.currtab.cd1!=0 &&  */}
                <ListView
                    key={useBodyScroll ? '0' : '1'}
                    ref={el => (this.lv = el)}
                    dataSource={this.state.dataSource}
                    renderRow={row}
                    useBodyScroll={useBodyScroll}

                    // style={{
                    //     height: document.documentElement.clientHeight,
                    //     }}
                    
                    style={useBodyScroll? {} : {
                            height: document.documentElement.clientHeight,
                            }}
                    pullToRefresh={
                        <PullToRefresh refreshing={this.state.refreshing} onRefresh={this.onRefresh} />
                      }
                    onEndReached={this.onEndReached}
                    
                    renderFooter={() =>{
                        if(this.state.noData) { return '暂无数据'; }
                        // if(this.state.secondtabSelect.cd1==0){
                        //     return;
                        // }else{
                            if(this.state.finish){
                                return '已经全部加载完毕'
                            }else{
                                return '正在加载更多的数据...'
                            }
                        // }
                    }}
                    />
                {/* } */}
                
                
                <img
                    style={{ width: '25%', position:"fixed", top:'70%', right:'30px'}}
                    src={require('../../images/red_bag.gif')}
                    onClick={(e)=>{this.redClick()}}
                />
            </View>
        )
    }
}


export default Home


